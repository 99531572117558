import { 
  ExpandMore, 
  ChevronRight, 
  Code, 
  Description, 
  Phone, 
  Email, 
  FileOpen, 
  Link as WebLink 
} from "@mui/icons-material"
import { TreeView, TreeItem } from "@mui/lab"
import { Typography, Box, Link } from "@mui/material"
import { Section } from "../../Section/Section"

export const More = () => {
  return (
    <>
      <Section title="More">
        <TreeView
          defaultCollapseIcon={
            <ExpandMore />
          }
          defaultExpandIcon={
            <ChevronRight />
          }
          sx={{
            textAlign: 'left'
          }}
          disableSelection={true}
        >
          <TreeItem nodeId="1" label="Projects">
            <TreeItem nodeId="1.1" label="Agile Tool-Box">
              <Typography variant="body2" paragraph={true}>
                Various Agile related tools in a single spot. Meant for refining of issues and tasks.
                <Box> <WebLink /> <Link href="/agile-tools" target="_new">Running Application</Link></Box>
              </Typography>
            </TreeItem>
            <TreeItem nodeId="1.2" label="Greg's Resum&eacute; Builder">
              <Typography variant="body2" paragraph={true}>
                This project is currently in progress, but will eventually be a fully functional resum&eacute; builder application.
                It incorporates a REST API written in (of all things) PHP. The front-end is written in React, predominantly using Material-UI.
                <Box> <WebLink /> <Link href="/resume" target="_new">Running Application</Link></Box>
                <Box> <Code /> <Link href="https://bitbucket.org/gmiley/resume/src/master/" target="_new">Bitbucket</Link></Box>
                <Box> <Description /> <Link href="/resume/api/spec/v1.yaml" target="_new">OpenAPI Spec</Link></Box>
              </Typography>
            </TreeItem>
          </TreeItem>
          <TreeItem nodeId="2" label="Personal Information">
            <Typography variant="body2" paragraph={true}>
              <Box> <Phone /> <Link href="tel:239-671-1667">239-671-1667</Link></Box>
              <Box> <Email /> <Link href="mailto://gmiley@gmail.com">gmiley@gmail.com</Link></Box>
              <Box> <FileOpen /> <Link href="/downloads/Gregory_Miley_public_resume.docx">Resum&eacute;</Link></Box>
            </Typography>
          </TreeItem>
        </TreeView>
      </Section>
    </>
  );
}