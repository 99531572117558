import { AppBar, Avatar, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const Header = () => {
  return (
    <>
      <Box sx={{
          position: 'fixed',
          top: 0,
          width: '100%'
        }}>
        <AppBar position="static">
          <Toolbar>
            <Avatar sx={{ margin: 1, spacingRight: '5px' }} alt="Portrait Photograph" src="/images/portrait.jpeg" />
            <Typography variant="h6" component="div" align="left" sx={{ flexGrow: 1 }}>
              Gregory S. Miley
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Toolbar sx={{ height: '50px' }} />
    </>
  );
}