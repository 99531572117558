import { Typography } from "@mui/material";
import { Section } from "../../Section/Section";

export const AboutMe = () => {
  return (
    <>
      <Section title="About Me">
        <Typography paragraph={true}>
          I am a senior software engineer who has been professionally working in the field for over twenty years, and 
          recreationally around thirty. Twelve in the government sector, and ten-plus years in private banking and
          credit card merchant acquisition, specializing in AML/KYC.
        </Typography>
        <Typography paragraph={true}>
          I am well versed in various programming languages, including the .Net Framework (C#, VB, etc.), Java, C/C++, 
          ANSI SQL (as well as multiple DBMS specific variants: Oracle PL/SQL followed by Microsoft T-SQL being the 
          two primaries, then MySQL and others to round it out), JavaScript and TypeScript, multiple shell scripting 
          languages (Bash, PowerShell, DOS/Windows Batch, etc.), and an honorable mention &#128513; of Visual FoxPro.
        </Typography>
        <Typography paragraph={true}>
          I have also worked across multiple platforms, such as: Windows (probably starting with 3.1, if memory serves),
          UNIX (and the rainbow of flavors that it has spawned: HP-UX, Linux, Solaris, etc.), and just enough of a taste
          of Mac to stay away for good.
        </Typography>
        <Typography paragraph={true}>
          Over the years, I have worked within several diverse team styles. From solo development
          to multi-team hubs. Within those various structures I have had the chance to try different methodologies,
          Waterfall, RAD, and Agile. Of those, my favorite is Agile - more specifically: Scrum. Although primarily
          using Scrum, my current development team, and our department in general, have adopted several of the XP 
          concepts and practices, such as: Test Driven Development, Pair Programming, and Collective Ownership.
        </Typography>
        <Typography paragraph={true}>
          I have used these combined skills to develop numerous enterpise level applications utilizing a myriad of 
          architectures, including: REST and SOAP API services, daemon services, desktop, client-server and, console
          applications. 
        </Typography>
      </Section>
    </>
  );
}