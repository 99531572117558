import { ThemeOptions } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles"

const defaultTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#FFF',
    },
  },
};

export const InitialTheme: Theme = createTheme(defaultTheme);
export default InitialTheme;