import { Typography } from "@mui/material";
import { AboutMe } from "./AboutMe/AboutMe";
import { More } from "./More/More";

export const Home = () => {
  return (
    <>
      <Typography 
        align="left" 
        paddingLeft="25px" 
        paddingRight="25px" 
        component="div" 
        variant="inherit"
      >
        <AboutMe />
        <More />
      </Typography>
    </>
  );
}