import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PropsWithChildren } from "react";

type SectionProps = {
  title: string
}

export const Section = (props: PropsWithChildren<SectionProps>) => {
  return (
    <>
      <Typography paragraph={true} />
      <Box sx={{
        backgroundColor: '#DDD',
        borderColor: '#CCC',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 5,
        paddingLeft: '15px',
        paddingRight: '15px',
      }}>
        <Typography variant="h5">
          {props.title}
        </Typography>
      </Box>
      <Card sx={{
        paddingLeft: '20px',
        paddingRight: '20px',
        backgroundColor: '#F5F5FF',
      }}>
        {props.children}
      </Card>
    </>
  );
}