import { AppBar, Toolbar, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

export const Footer = () => {
  return (
    <>
      <Toolbar sx={{ height: '100px' }} />
      <Box sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%'
      }}>
        <AppBar position="static">
          <Toolbar>
            <Typography component="div" sx={{ flexGrow: 1 }}>
              <Link href="https://gregory.miley-family.com" color="#FFF">
                https://gregory.miley-family.com
              </Link>
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}