import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { Home } from "./Content/Home/Home";
import InitialTheme from "./Theme/InitialTheme";
import { ThemeProvider } from "@emotion/react";

export const AppRoot = () => {
  return (
    <>
      <ThemeProvider theme={InitialTheme}>
        <Header />
        <Home />
        <Footer />
      </ThemeProvider>
    </>
  );
}